<template>
  <el-col
    :span="span"
    :md="span > 8 ? span : 8"
    :sm="span > 8 ? span : 12"
    :xs="span > 8 ? span : 24"
    class="descriptions-item"
  >
    <div class="descriptions-item-content">
      <div v-if="label" class="descriptions-item-label">
        {{ label }}{{ colon ? '：' : '' }}
      </div>
      <div class="descriptions-item-value">
        <slot></slot>
      </div>
    </div>
  </el-col>
</template>

<script>
// :md="8"
// :sm="12"
// :xs="24"
export default {
  name: 'DescriptionItem',
  props: {
    spanMap: {
      type: Object,
      required: false,
      default: () => {},
    },
    span: {
      type: Number,
      required: false,
      default: 8,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    colon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.descriptions-item {
  padding-bottom: 16px;
  padding-right: 12px;
  span {
    display: inline-block;
  }
  .descriptions-item-content {
    display: flex;
    justify-content: flex-start;
    //align-items: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    width: 100%;
    .descriptions-item-label {
      flex-grow: 0;
      flex-shrink: 0;
      color: #606266;
      font-size: 14px;
      line-height: 1.5;
    }
    .descriptions-item-value {
      color: #000000;
      flex-grow: 1;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}
</style>
