<template>
  <el-dialog :visible.sync="visible" :title="$t('cardList.detail')" width="70%">
    <description-list title="基本信息">
      <description-item label="id">{{ pageData.id }}</description-item>
      <description-item label="权益包id">{{
        pageData.packageId
      }}</description-item>
      <description-item label="用户id">{{ pageData.userId }}</description-item>
      <description-item label="手机号">{{ pageData.mobile }}</description-item>
      <description-item label="来源">{{ pageData.source }}</description-item>

      <description-item label="有效期开始时间">{{
        pageData.startDate
      }}</description-item>
      <description-item label="有效期结束时间">{{
        pageData.endDate
      }}</description-item>
      <description-item label="激活码">{{
        pageData.activateCode
      }}</description-item>
      <description-item label="激活时间">{{
        pageData.activateDate
      }}</description-item>
      <description-item label="批次id">{{ pageData.batchId }}</description-item>
      <description-item label="创建时间">{{
        pageData.createDate
      }}</description-item>
      <description-item label="创建者">{{ pageData.creator }}</description-item>
      <description-item label="激活状态">{{
        pageData.status == 1 ? '已激活' : '未激活'
      }}</description-item>
      <description-item label="更新时间">{{
        pageData.updateDate
      }}</description-item>
      <description-item label="更新者">{{ pageData.updater }}</description-item>
      <description-item label="合作方">{{ pageData.appCode }}</description-item>
    </description-list>
    <description-list title="权益列表">
      <el-table :data="userRightList" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <h3>使用流水</h3>

            <el-table :data="props.row.rightFlowList" style="width: 100%">
              <el-table-column label="流水id" prop="id"> </el-table-column>
              <el-table-column label="用户id" prop="userId"> </el-table-column>
              <el-table-column label="权益id" prop="rightId"> </el-table-column>
              <el-table-column label="用户权益id" prop="userRightId">
              </el-table-column>
              <el-table-column label="创建时间" prop="createDate">
              </el-table-column>
              <el-table-column label="更新时间" prop="updateDate">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="用户权益id" prop="id"> </el-table-column>
        <el-table-column label="权益名称" prop="rightName"> </el-table-column>
        <el-table-column label="权益id" prop="rightId"> </el-table-column>
        <el-table-column label="权益卡id" prop="cardId"> </el-table-column>

        <el-table-column label="权益代码" prop="rightCode"> </el-table-column>
        <el-table-column label="等待期" prop="waitPeriod"> </el-table-column>

        <el-table-column label="激活有效期" prop="activatePeriod">
        </el-table-column>
        <el-table-column
          label="权益可使用的次数(-1：无限次)"
          prop="availableCount"
          fixed="right"
        >
        </el-table-column>
      </el-table>
    </description-list>
    <!-- <el-tree
      :data="mainInfo"
      :props="defaultProps"
      accordion
      :highlight-current="true"
      class="rights-tree"
    >
    </el-tree> -->
  </el-dialog>
</template>

<script>
// import fakeData from './data.js';
import DescriptionList from '@/components/DescriptionList';
import DescriptionItem from '@/components/DescriptionItem';
export default {
  components: {
    DescriptionList,
    DescriptionItem,
  },
  data() {
    return {
      tableData: [
        {
          id: '12987122',
          name: '好滋好味鸡蛋仔',
          category: '江浙小吃、小吃零食',
          desc: '荷兰优质淡奶，奶香浓而不腻',
          address: '上海市普陀区真北路',
          shop: '王小虎夫妻店',
          shopId: '10333',
        },
      ],
      pageData: {},
      defaultProps: {
        children: 'children',
        label: 'key',
      },
      visible: true,

      mainInfo: [],
      dataForm: {
        id: '',
        name: '',
        content: '',
        subList: [],
      },
      activeNames: ['1'],
      dataName: {
        id: 'id',
        packageId: '权益包id',
        mobile: '手机号',
        source: '来源',
        startDate: '有效期开始时间',
        endDate: '有效期结束时间',
        activateCode: '激活码',
        activateDate: '激活时间	',
        batchId: '批次id',
        createDate: '创建时间',
        creator: '创建者',
        status: '状态(0/未使用,1/已使用)',
        updateDate: '更新时间',
        updater: '更新者',
        appCode: '合作方',
        userId: '用户id',
        activatePeriod: '激活有效期(自生成日期开始的激活有效时间)	',
        availableCount: '权益可使用的次数(-1：无限次)	',
        cardId: '权益卡id',
        rightCode: '权益代码',
        rightId: '权益id',
        rightName: '权益名称	',
        waitPeriod: '等待期(自激活日期开始计算的天数)',
        userRightId: '用户权益id',
        userRightList: '权益列表',
        rightFlowList: '使用流水',
      },
      dataNameLevel2: {
        status: '状态（0/未使用 1/已使用）',
      },
      userRightList: [], // 权益列表
    };
  },
  methods: {
    init() {
      this.visible = true;
      if (this.dataForm.id) {
        this.queryData();
      }
    },
    queryData() {
      this.$http
        .get('/health/card/info?id=' + this.dataForm.id)
        .then((res) => {
          if (res.data.code === 0) {
            this.pageData = res.data.data;
            this.userRightList = res.data.data.userRightList;
            const preData = Object.entries(res.data.data);
            const handleData = (data) => {
              const filterValue = (key, value) => {
                const stash = [];
                value.length &&
                  value.map((i, j) => {
                    stash.push({
                      key: j + 1,
                      children: handleKeyValueLevelOrigin(i),
                    });
                  });
                return stash;
              };
              const handleKeyValueLevelOrigin = (value) => {
                const stash = []; // 权益列表
                for (let key in value) {
                  if (Array.isArray(value[key])) {
                    if (!!value[key] && !!this.dataName[key]) {
                      stash.push({
                        key: this.dataName[key],
                        children: handleKeyValue(key, value[key]),
                      });
                    }
                  } else {
                    if (!!value[key] && !!this.dataName[key]) {
                      stash.push({
                        key:
                          this.dataName[key] +
                          ' : ' +
                          (value[key] ? value[key] : '无'),
                        children: handleKeyValue(key, value[key]),
                      });
                    }
                  }
                }
                return stash;
              };
              const handleKeyValue = (key, value) => {
                const stash = [];
                if (Array.isArray(value)) {
                  value.map((i, j) => {
                    stash.push({
                      key: j + 1,
                      children: handleKeyValueLevel2(i),
                    });
                  });
                }
                return stash;
              };
              const handleKeyValueLevel2 = (value) => {
                // 流水
                const stash = [];
                for (let key in value) {
                  if (!!value[key] && !!this.dataName[key]) {
                    stash.push({
                      key:
                        this.dataName[key] +
                        ' : ' +
                        (value[key] ? value[key] : '无'),
                    });
                  }
                }
                return stash;
              };
              const myFilter = (i) => {
                const [key, value] = i;
                if (Array.isArray(value)) {
                  const makeValue = filterValue(key, value);
                  console.log(makeValue);
                  if (!!this.dataName[key]) {
                    moduleData.push({
                      key: this.dataName[key],
                      value,
                      children: makeValue,
                    });
                  }
                } else {
                  console.log(value);
                  if (!!value && !!this.dataName[key]) {
                    moduleData.push({
                      key: this.dataName[key] + ' : ' + (value ? value : '无'),
                    });
                  }
                }
              };
              const moduleData = [];
              data.map(myFilter);
              return moduleData;
            };
            const targetData = handleData(preData);
            this.mainInfo = targetData;

            console.log(this.mainInfo, 'this.mainInfo');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.rights-tree {
  .el-tree-node__content {
    display: flex;
  }
  .el-tree-node__label {
    display: block;
    width: 100px;
  }
}
</style>
