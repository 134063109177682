<template>
  <div class="descriptions">
    <div v-if="Boolean(title)" class="descriptions-title">{{ title }}</div>
    <el-card class="descriptions-view">
      <el-row class="descriptions-row">
        <slot v-if="$slots.default" />
        <div v-else style="text-align: center; color: grey;">暂无数据</div>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'DescriptionList',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.descriptions {
  .descriptions-title {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, .85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
  }

  .descriptions-view {
    margin-bottom: 24px;

    .descriptions-row {
      display: flex;
      flex-wrap: wrap;
    }
  }

}

</style>
