<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="healthRifhts">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <el-form-item>
          <el-input
            v-model="dataForm.activateCode"
            :placeholder="$t('cardList.activateCode')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.cardId"
            placeholder="权益卡ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.batchId"
            :placeholder="$t('cardList.batchId')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.mobile"
            :placeholder="$t('cardList.mobile')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.sendMobile"
            :placeholder="$t('cardList.sendMobile')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.packageId"
            :placeholder="$t('rightsCard.packageId')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="dataForm.startDate"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('package.activateStartDate')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="dataForm.endDate"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('package.activateEndDate')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="dataForm.appCode"
            placeholder="合作方"
            label="appDesc"
            :clearable="true"
            style="width: 100%"
          >
            <el-option
              v-for="item in listByScope"
              :key="item.appCode"
              :label="item.appDesc"
              :value="item.appCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="dataForm.status"
            :placeholder="$t('cardList.status')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getDataList()">{{
            $t('query')
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetSearch()">{{
            $t('cardList.reset')
          }}</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="权益卡ID"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="packageId"
          :label="$t('rightsCard.packageId')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="packageCode"
          :label="$t('package.packageCode')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="packageName"
          :label="$t('rightsCard.packageName')"
          header-align="center"
          align="center"
        >
          <!-- <template slot-scope="scope">
            <span>{{ computedPackageName(scope.row.packageId) }}</span>
          </template> -->
        </el-table-column>
        <el-table-column
          prop="appCode"
          label="合作方"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="activateCode"
          :label="$t('cardList.activateCode')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="activateDate"
          :label="$t('package.activateDate')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          :label="$t('cardList.mobile')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="sendMobile"
          :label="$t('cardList.sendMobile')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('cardList.status')"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ statusHandler(scope.row.status) }}
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="source"
          :label="$t('cardList.source')"
          header-align="center"
          align="center"
        >
        </el-table-column> -->

        <el-table-column header-align="center" align="center" width="150">
          <template slot="header">
            <el-tooltip class="item" effect="light" placement="top">
              <div slot="content">
                batch：批次来源<br />policy ：保单来源 <br />order ：订单来源<br />appCode:第三方来源
              </div>
              <p>
                {{ $t('cardList.source') }}
                <i class="el-icon-warning-outline"></i>
              </p>
            </el-tooltip>
          </template>

          <template slot-scope="scope">
            <p>{{ scope.row.source }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="status"
          :label="$t('cardList.status')"
          header-align="center"
          align="center"
          :filters="statusArray"
          :filter-method="filterStatus"
          :filter-multiple="false"
        >
          <template slot-scope="scope">
            <span>{{ computedStatus(scope.row.status) }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="createDate"
          :label="$t('rightsCard.createDate')"
          sortable="custom"
          header-align="center"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showDetailFuc(scope.row)"
              >{{ $t('cardList.detail') }}</el-button
            >
            <el-button type="text" size="small" @click="resend(scope.row)">{{
              '重发'
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
      <show-card-detail
        v-if="showDetail"
        ref="showCardDetail"
      ></show-card-detail>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import showCardDetail from './show-card-detail';
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      listByScope: [],
      mixinViewModuleOptions: {
        getDataListURL: '/health/card/page',
        getDataListIsPage: true,
        exportURL: '/health/cardbatch/export',
      },
      //  0：未激活 1：已激活
      options: [
        {
          value: '0',
          label: '未激活',
        },
        {
          value: '1',
          label: '已激活',
        },
        {
          value: '',
          label: '全部状态',
        },
      ],
      dataForm: {
        activateCode: '', // 激活码
        batchId: '', // 批次ID
        endDate: '', // 激活结束时间
        startDate: '', // 激活开始时间
        mobile: '', // 激活手机号
        packageId: '', // 权益包id
        status: '', // 状态 0：未激活 1：已激活
        sendMobile: '', // 发送手机号
        cardId: '', // 权益卡ID
      },
      statusArray: [
        {
          value: 0,
          text: this.$t('cardList.noActive'),
        },
        {
          value: 1,
          text: this.$t('cardList.actived'),
        },
      ],
      showDetail: false,
    };
  },
  methods: {
    getListByScope() {
      this.$http
        .get('/sysapp/listByScope')
        .then((res) => {
          console.log(res, 'listByScope');
          if (res.data.code === 0) {
            this.listByScope = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async resend(row) {
      let params = {
        id: row.id,
      };
      const { data: res } = await this.$http.get('/health/card/resend', {
        params,
      });
      const { data, code, msg } = res;
      if (code === 0) {
        this.$notify({
          title: '提示',
          message: '短信重发成功',
          type: 'info',
          duration: 5000,
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: '提示',
          message: msg,
          type: 'info',
          duration: 5000,
        });
      }
    },
    statusHandler(data) {
      const demo = this.options.filter((i) => i.value === String(data));
      return (demo.length && demo[0].label) || '';
    },
    resetSearch() {
      this.dataForm = {
        activateCode: '', // 激活码
        batchId: '', // 批次ID
        endDate: '', // 激活结束时间
        startDate: '', // 激活开始时间
        mobile: '', // 手机号
        packageId: '', // 权益包id
        status: '', // 状态 0：未激活 1：已激活
      };
    },
    showDetailFuc(row) {
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.showCardDetail.dataForm.id = row.id;
        this.$refs.showCardDetail.init();
      });
    },
    computedStatus(val) {
      const obj = {
        0: this.$t('cardList.noActive'),
        1: this.$t('cardList.actived'),
      };
      return obj[val];
    },
    filterStatus(value, row) {
      return row.status == value;
    },
  },
  components: {
    showCardDetail,
  },
  created() {
    this.$bus.on('card_list_refresh', this.getDataList);
    this.getListByScope();
  },
};
</script>
